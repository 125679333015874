<template>
  <div class="container">
    <div class="top-btn">
      <!-- <el-button type="info" @click="onback" round>返回</el-button> -->
      <div class="back-button" @click="onback" :style="{'margin-top':statusBarHeight}">
        <i class="icon-leftarrow"></i>
        <span class="title">返回</span>
      </div>
    </div>

    <div style="width: 100%; height: auto">
      <div class="loading-div" />

      <pdf
        class="pdf"
        :page="currentPage"
        @num-pages="pageCount = $event"
        ref="pdf"
        :src="pdfUrl"
      >
      </pdf>
    </div>

    <div v-if="pageCount > 1" class="next-pager">
      <button class="btn-pager" @click="beforeClick">上一頁</button>
      <div style="width: 60%"></div>
      <button class="btn-pager" @click="nextClick">下一頁</button>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      pdfUrl: "", //文件路徑
      currentPage: 1, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      statusBarHeight:'0px',
    };
  },
  methods: {
    onback() {
      this.$router.go(-1);
    },
    beforeClick() {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
      }
    },
    nextClick() {
      if (this.currentPage < this.pageCount) {
        this.currentPage = this.currentPage + 1;
      }
    },
  },
  created: function () {
     if(this.$store.state.statusBarHeight > 0){
			this.statusBarHeight = (this.$store.state.statusBarHeight - 20) + 'px';
		}
    this.pdfUrl = this.$route.query.pdfUrl;
    this.pageCount = pdf.pageCount;
  },
};
</script>

<style lang="scss" scoped>
.btn-pager {
  width: 100px;
  height: 40px;
  margin: 10px;
}
.next-pager {
  width: 100%;
  height: auto;
  flex-direction: row;
  display: flex;
  position: absolute;
  bottom: 0;

  justify-content: center;
}
// 这里是禁止长按选中文字
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #ccc;
  color: white;
  flex-direction: row;
}
.back-button {
  width: auto;
  border-radius: 2px;
  height: auto;
  color: white;
  font-size: 26px;
}
.top-btn {
  height: auto;
  width: auto;
  padding: 15px;
}
.pdf {
  position: absolute;
  width: 100%;
  height: auto;
}
.loading-div {
  text-align: center;
  position: absolute;
  left: 40%;
  top: 40%;
  width: 60px;
  height: 60px;
  display: flex;
  // background-color: aquamarine;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../assets/img/loading.gif");
}
</style>